import { Link } from 'gatsby';
import React, { ReactElement, useState } from 'react';
import { GrFormDown } from 'react-icons/gr';

import Logo from '../../svg/logo.svg';

const linkActiveClasses = 'text-gray-500';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const changeShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const links = [
    <Link key="home" className="hover:text-gray-500 pb-2" activeClassName={linkActiveClasses} to="/">
      Home
    </Link>,
    <Link key="food" className="hover:text-gray-500 pb-2" activeClassName={linkActiveClasses} to="/food">
      Food
    </Link>,
    <Link key="lifestyle" className="hover:text-gray-500 pb-2" activeClassName={linkActiveClasses} to="/lifestyle">
      Lifestyle
    </Link>,
    <Link key="about" className="hover:text-gray-500 pb-2" activeClassName={linkActiveClasses} to="/about">
      About
    </Link>,
    <Link key="services" className="hover:text-gray-500 pb-2" activeClassName={linkActiveClasses} to="/services">
      Services
    </Link>,
    <Link key="contact" className="hover:text-gray-500 pb-4" activeClassName={linkActiveClasses} to="/contact">
      Contact
    </Link>,
  ];
  return (
    <header className="pb-8">
      <div className="flex justify-center">
        <Link to="/">
          <Logo className="cursor-pointer h-24 w-full py-4 px-8" />
        </Link>
      </div>
      <div
        className="flex flex-row justify-items-center items-center sm:hidden space-x-2 pb-2"
        onClick={changeShowMenu}
      >
        <div className="text-sm">Menu</div>
        <div>
          <GrFormDown
            size="1em"
            className={showMenu ? 'transform rotate-180 transition duration-300' : 'transition duration-300'}
          />
        </div>
      </div>
      <div className="sm:hidden flex absolute bg-white w-full right-0 z-50 px-4">
        {showMenu ? <h2 className="flex flex-col pt-2 text-sm">{links}</h2> : null}
      </div>
      <nav className="hidden sm:block">
        <ul className="header__menu flex flex-row flex-no-wrap pt-4 pb-8 whitespace-nowrap justify-center space-x-6 text-sm uppercase">
          {links.map((link: ReactElement) => {
            return <li key={link.key}>{link}</li>;
          })}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
